"use client"
import type { Asset, InputAssetIds, InputCreateAsset, InputUpdateAsset, MediaLibrary } from "types"

import type { CloudinaryUploadWidgetInfo } from "next-cloudinary"
import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { activeBrandSub, blxQ, mediaApi, store } from "../.."
import { createSelectors } from "../../utilities"

export interface InitialMediaState {
    mediaLibrary: MediaLibrary
}

export interface MediaActions {
    setMediaLibrary: (mediaLibrary: MediaLibrary) => void
    createAsset: (cloudinaryAsset: CloudinaryUploadWidgetInfo) => Promise<Asset>
    deleteAsset: (asset: Asset) => Promise<void>
    updateAsset: (asset: Asset) => Promise<Asset>
}

export type MediaState = MediaActions & InitialMediaState

const defaultValues: InitialMediaState = {
    mediaLibrary: null
}

export const mediaState = createSelectors(
    create<MediaState>()(
        devtools(
            (set, get) => ({
                ...defaultValues,
                setMediaLibrary: (mediaLibrary) => {
                    set({ mediaLibrary })
                },
                deleteAsset: async (asset) => {
                    const brandId = activeBrandSub(store.getState())
                    const variables: InputAssetIds = {
                        brandId: asset?.brand?.id || brandId,
                        assetId: asset.id
                    }
                    await blxQ(mediaApi.endpoints.deleteAsset.initiate(variables))
                },
                updateAsset: async (asset) => {
                    const brandId = asset?.brand?.id || activeBrandSub(store.getState())
                    const variables: InputUpdateAsset = {
                        brandId,
                        assetId: asset?.id,
                        alt: asset?.alt,
                        type: asset?.type,
                        brand: asset?.brand?.id,
                        s3AssetFields: null,
                        bytes: asset?.bytes,
                        width: asset?.width,
                        height: asset?.height,
                        cloudinaryAssetFields: null,
                        caption: asset?.caption,
                        category: asset?.category,
                        tags: asset?.tags
                    }

                    const assetResponse = await blxQ(
                        mediaApi.endpoints.updateAsset.initiate(variables)
                    )

                    return assetResponse?.["data"]
                },
                createAsset: async (cloudinaryAsset) => {
                    const brandId = activeBrandSub(store.getState())
                    const variables: InputCreateAsset = {
                        alt: cloudinaryAsset?.original_filename,
                        type: `${cloudinaryAsset?.resource_type}/${cloudinaryAsset?.format}`,
                        brandId,
                        brand: null,
                        s3AssetFields: null,
                        bytes: cloudinaryAsset?.bytes,
                        width: cloudinaryAsset?.width,
                        height: cloudinaryAsset?.height,
                        cloudinaryAssetFields: {
                            cloudinaryId: cloudinaryAsset?.public_id
                        },
                        tags: null
                    }

                    const mutationResponse = await blxQ(
                        mediaApi.endpoints.createAsset.initiate(variables)
                    )
                    return mutationResponse?.["data"]
                }
            }),
            { name: "mediaState" }
        )
    )
)

export const mediaActions = {
    getMediaLibrary: mediaState.getState().mediaLibrary,
    setMediaLibrary: mediaState.getState().setMediaLibrary,
    createAsset: mediaState.getState().createAsset,
    deleteAsset: mediaState.getState().deleteAsset,
    updateAsset: mediaState.getState().updateAsset
}
