import { toastActions } from "notifications/src/toasts"
import {
    type Brand,
    type CompositeProduct,
    type CreateProduct,
    type DeleteProduct,
    type InputGetAll,
    type InputGetById,
    type InputGetBySlug,
    type InputGetProductLocations,
    type InputLocationProductRelationship,
    type InputSearchBpl,
    type LocationProductRelationship,
    type LocationProductRelationshipConnection,
    OpenSearchType,
    type Order,
    type Product,
    ProductTypeFilter,
    type StyleConnection,
    type UpdateProduct,
    type WildCardTitleSearchConnection
} from "types"
import { getAllBeerStyles } from "utilities"
import { createProduct } from "../mutations/private/product/createProductMutation"
import { createProductMutationInventory } from "../mutations/private/product/createProductMutation-Inventory"
import { deleteProduct } from "../mutations/private/product/deleteProductMutation"
import updateLocationProductRelationship from "../mutations/private/product/updateLocationProductRelationships"
import { updateProduct } from "../mutations/private/product/updateProductMutation"
import { getProductByIdPPAccount } from "../queries/private/product/getProductById-PP-account"
import { getProductByIdBrewInsights } from "../queries/private/product/getProductById-brewknowledge"
import { getProductByIdQuery } from "../queries/private/product/getProductByIdQuery-PP-inventory"
import { getProductBySlugQuery } from "../queries/private/product/getProductBySlugQuery"
import { getProductLocationsQuery } from "../queries/private/product/getProductLocationsQuery"
import { getProductsByBrandId } from "../queries/private/product/getProductsByBrandId"
import { plGetProductByIdQuery } from "../queries/private/product/plGetProductByIdQuery"
import searchBplProducts from "../queries/private/product/searchBplProducts"
import { allStyles } from "../queries/private/style/getAllStylesQuery-MMB"
import { baseApi } from "./baseApi"

export type LimitedProductSearchInput = {
    searchTerm?: string
    limit?: number
    brewType?: ProductTypeFilter
    order?: Order
    orderBy?: string
    nextToken?: string
}

const productApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductBySlug */
        getProductBySlug: builder.query<Product, InputGetBySlug>({
            //! NEEDS: TESTING
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductBySlugQuery,
                args: input
            }),
            transformResponse: (data: Product): Product => {
                // TODO: Add notifications
                //! NEEDS: productActions
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getProductBySlug", error)
                return error
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductLocations */
        getProductLocations: builder.query<
            LocationProductRelationshipConnection,
            InputGetProductLocations
        >({
            providesTags: ["Products", "ProductLocations"],
            query: (input) => ({
                query: getProductLocationsQuery,
                args: input
            }),
            transformResponse: (
                data: LocationProductRelationshipConnection
            ): LocationProductRelationshipConnection => {
                // TODO: Add notifications
                //! NEEDS: productActions
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getProductLocations", error)
                return error
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductById */
        getProductById: builder.query<Product, InputGetById>({
            //! NEEDS: TESTING
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductByIdQuery,
                args: { ...input }
            }),
            transformResponse: (data: Product): Product => {
                // TODO: Add notifications
                //! NEEDS: productActions
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getProductById", error)
                return error
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductById */
        getProductByIdBrewInsights: builder.query<Product, InputGetById>({
            //! NEEDS: TESTING
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductByIdBrewInsights,
                args: { ...input }
            }),
            transformResponse: (data: Product): Product => {
                // TODO: Add notifications
                //! NEEDS: productActions
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getProductById", error)
                return error
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductById */
        getProductByIdPpAccount: builder.query<Product, InputGetById>({
            //! NEEDS: TESTING
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductByIdPPAccount,
                args: input
            }),
            transformResponse: (data: Product): Product => {
                // TODO: Add notifications
                //! NEEDS: productActions
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getProductById", error)
                return error
            }
        }),
        getProductsByBrandId: builder.query<Brand, InputGetById>({
            providesTags: ["Products"],
            query: (input) => ({
                query: getProductsByBrandId,
                args: input
            })
        }),
        getAllStyles: builder.query<StyleConnection, InputGetAll>({
            providesTags: ["Products"],
            query: (input) => ({
                query: allStyles,
                args: input
            })
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createProduct */
        createProduct: builder.mutation<Product, CreateProduct>({
            invalidatesTags: ["Products"],
            //! NEEDS: TESTING
            query: (input) => ({
                query: createProduct,
                args: input
            }),
            transformResponse: (data: Product): Product => {
                // TODO: Add notifications
                //! NEEDS: productActions
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/createProduct", error)
                return error
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createProduct */
        createProductPpInventory: builder.mutation<Product, CreateProduct>({
            //! NEEDS: TESTING
            query: (input) => ({
                query: createProductMutationInventory,
                args: input
            }),
            transformResponse: (data): Product => {
                // TODO: Add notifications
                //! NEEDS: productActions
                return data as Product
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/createProduct", error)
                return error
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateProduct */
        updateProduct: builder.mutation<Product, UpdateProduct>({
            invalidatesTags: ["Products"],
            //! NEEDS: TESTING
            query: (input) => ({
                query: updateProduct,
                args: input
            }),
            transformResponse: (data: Product): Product => {
                // TODO: Add notifications
                //! NEEDS: productActions
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/updateProduct", error)
                return error
            }
        }),
        deleteProduct: builder.mutation<{ id: string }, DeleteProduct>({
            query: (input) => ({
                query: deleteProduct,
                args: input
            }),
            onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
                await queryFulfilled
                setTimeout(() => {
                    // TODO: refetch immediately once searchBPL issues are fixed
                    dispatch(productApi.util.invalidateTags(["Products"]))
                }, 5000)
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateProduct */
        updateProductAvailability: builder.mutation<
            LocationProductRelationshipConnection,
            InputLocationProductRelationship
        >({
            invalidatesTags: ["ProductLocations"],
            query: (input) => ({
                query: updateLocationProductRelationship,
                args: input
            }),
            transformResponse: (data: any): any => {
                //! NEEDS: productActions
                // productActions
                toastActions.addToast({
                    title: "Availabilty Updated",
                    description: "Availabilty Updated",
                    variant: "success",
                    duration: 3000
                })
                return data as LocationProductRelationship[]
            },
            transformErrorResponse: (error) => {
                toastActions.addToast({
                    title: "Availabilty Update Failed",
                    description: "Availabilty Update Failed",
                    variant: "error",
                    duration: 3000
                })
                console.error("RTKQ/transformErrorResponse/updateProductAvailability", error)
                return error
            }
        }),
        getAllBeerStyles: builder.query<StyleConnection, InputGetAll>({
            query: (input) => ({
                query: getAllBeerStyles,
                args: input
            }),
            transformResponse: (data: StyleConnection): StyleConnection => {
                // TODO: Add notifications
                //! NEEDS: productActions
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/updateProduct", error)
                return error
            }
        }),
        limitedProductSearch: builder.query<
            WildCardTitleSearchConnection,
            LimitedProductSearchInput
        >({
            providesTags: ["Products"],
            query: (input) => ({
                query: searchBplProducts,
                args: {
                    limit: input.limit || 25,
                    typeFilters: [OpenSearchType.Product],
                    order: input.order,
                    orderBy: input.orderBy,
                    nextToken: input.nextToken,
                    search: {
                        wildcardText: input.searchTerm || "",
                        searchFieldsProducts: {
                            searchFieldsProductsWeights: {
                                brandObjectTitleWeight: 55,
                                descriptionWeight: 0,
                                titleWeight: 40
                            },
                            brewTypes: input.brewType ? [input.brewType] : undefined
                        }
                    }
                }
            })
        }),
        productSearch: builder.query<
            CompositeProduct[],
            {
                productTypeFilter?: ProductTypeFilter
                searchTerm?: string
            }
        >({
            providesTags: ["Products"],
            queryFn: async (arg, api, extraOptions, baseQuery) => {
                const CHUNK_SIZE = 750
                const input: InputSearchBpl = {
                    limit: CHUNK_SIZE,
                    typeFilters: [OpenSearchType.Product],
                    search: {
                        wildcardText: arg.searchTerm || "",
                        searchFieldsProducts: {
                            searchFieldsProductsWeights: {
                                brandObjectTitleWeight: 5,
                                descriptionWeight: 0,
                                titleWeight: 10
                            }
                        }
                    },
                    nextToken: null
                }

                if (arg.productTypeFilter && arg.productTypeFilter !== ProductTypeFilter.All)
                    Object.assign(input.search, {
                        searchFieldsProducts: {
                            brewTypes: [ProductTypeFilter[arg.productTypeFilter]]
                        }
                    })

                const initialResponse = await baseQuery({
                    query: searchBplProducts,
                    args: { ...input }
                })

                if (initialResponse.error) {
                    console.error("RTKQ/productSearch", initialResponse.error.message)
                    return { data: [] }
                }

                const data = initialResponse.data as WildCardTitleSearchConnection

                const results = [...data.items]
                let index = 750
                const promises = []
                while (index < data.totalResults) {
                    input.nextToken = String(index)
                    input.limit = Math.min(CHUNK_SIZE, 10000 - index)
                    promises.push(
                        baseQuery({
                            query: searchBplProducts,
                            args: { ...input }
                        })
                    )
                    index += CHUNK_SIZE
                }
                results.push(
                    ...(await Promise.all(promises)).flatMap((res) => res.data?.items || [])
                )

                return {
                    data: results as CompositeProduct[]
                }
            }
        }),
        PLGetProductById: builder.query<CompositeProduct, InputGetById>({
            providesTags: ["Products"],
            query: (input) => ({
                query: plGetProductByIdQuery,
                args: { ...input }
            })
        })
    })
})

const {
    useCreateProductMutation,
    useCreateProductPpInventoryMutation,
    useGetProductByIdPpAccountQuery,
    useGetProductByIdQuery,
    useGetProductBySlugQuery,
    useGetProductLocationsQuery,
    useLazyGetProductByIdPpAccountQuery,
    useLazyGetProductByIdQuery,
    useLazyGetProductBySlugQuery,
    useLazyGetProductLocationsQuery,
    useUpdateProductMutation,
    useDeleteProductMutation,
    useGetProductsByBrandIdQuery,
    useGetAllBeerStylesQuery,
    useLimitedProductSearchQuery,
    useProductSearchQuery,
    usePLGetProductByIdQuery
} = productApi

export {
    productApi,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createProduct */
    useCreateProductMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createProduct */
    useCreateProductPpInventoryMutation,
    useDeleteProductMutation,
    useGetAllBeerStylesQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductById */
    useGetProductByIdPpAccountQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductById */
    useGetProductByIdQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductBySlug */
    useGetProductBySlugQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductLocations */
    useGetProductLocationsQuery,
    useGetProductsByBrandIdQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductById */
    useLazyGetProductByIdPpAccountQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductById */
    useLazyGetProductByIdQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductBySlug */
    useLazyGetProductBySlugQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getProductLocations */
    useLazyGetProductLocationsQuery,
    useLimitedProductSearchQuery,
    usePLGetProductByIdQuery,
    useProductSearchQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateProduct */
    useUpdateProductMutation
}
