import { toastActions } from "notifications/src/toasts/state/useToasts"
import type {
    AddAssignedKeg,
    InputSuperAdminDeleteTapSensor,
    RearrangeTapSensorAssignedKegs,
    RemoveAssignedKeg,
    TapSensor
} from "types"
import type { RemoveAndReplaceTappedKeg } from "../../../../apps/ppp/src/types/schema/graphql"
import { addAssignedKegMutation } from "../mutations/private/inventory/addAssignedKegMutation"
import { deleteTapSensorMutation } from "../mutations/private/tapSensors/deleteTapSensorMutation"
import { rearrangeTapSensorAssignedKegsMutation } from "../mutations/private/tapSensors/rearrangeTapSensorAssignedKegsMutation"
import { removeAssignedKegMutation } from "../mutations/private/tapSensors/removeAssignedKegMutation"
import { baseApi } from "./baseApi"

const tapSensorMutationsApi = baseApi.injectEndpoints({
    // overrideExisting: true,
    endpoints: (builder) => ({
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getPPSubById */
        addKegAssignedKeg: builder.mutation<TapSensor, AddAssignedKeg>({
            invalidatesTags: [
                "TapSensors",
                { type: "TapSensors", id: "LIST" },
                "TapSensor",
                "UnassignedKegs",
                "TappedKegs"
            ],
            query: (input) => ({
                query: addAssignedKegMutation,
                args: input
            }),
            transformResponse: ({ data }: { data: TapSensor }): TapSensor => {
                // tapSensorActions.populateTapSensors({ tapSensors: data?.tapSensors })
                toastActions.addToast({ title: "Added Keg to TapSensor" })
                return data
            }
        }),
        removeAssignedKeg: builder.mutation<TapSensor, RemoveAssignedKeg>({
            invalidatesTags: [
                "TapSensors",
                { type: "TapSensors", id: "LIST" },
                "TapSensor",
                "UnassignedKegs"
            ],
            query: (input) => ({
                query: removeAssignedKegMutation,
                args: input
            }),
            transformResponse: ({ data }: { data: TapSensor }): TapSensor => {
                // tapSensorActions.populateTapSensors({ tapSensors: data?.tapSensors })
                toastActions.addToast({ title: "Removed assigned keg" })
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/updateKeg", error)
                toastActions.addToast({ title: "Error: Removing assigned keg" })
            }
        }),
        changeTapOfAssignedKegs: builder.mutation<
            void,
            { kegId: string; ppSubId: string; currentTapSensorId: string; newTapSensorId: string }
        >({
            invalidatesTags: [
                "TapSensors",
                { type: "TapSensors", id: "LIST" },
                "TapSensor",
                "UnassignedKegs"
            ],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                const removeAssignedKegArgs: RemoveAssignedKeg = {
                    kegId: arg.kegId,
                    tapSensorId: arg.currentTapSensorId,
                    ppSubId: arg.ppSubId
                }
                await baseQuery({
                    query: removeAssignedKegMutation,
                    args: removeAssignedKegArgs
                })

                const addAssignedKegArgs: AddAssignedKeg = {
                    kegId: arg.kegId,
                    tapSensorId: arg.newTapSensorId,
                    ppSubId: arg.ppSubId
                }
                await baseQuery({
                    query: addAssignedKegMutation,
                    args: addAssignedKegArgs
                })
                return { data: undefined }
            }
        }),
        removeAndReplaceTappedKeg: builder.mutation<TapSensor, RemoveAndReplaceTappedKeg>({
            //! TODO: NEEDS TESTING
            invalidatesTags: ["TapSensors", "TapSensor", "Kegs"],
            query: (input) => ({
                query: removeAssignedKegMutation,
                args: input
            }),
            transformResponse: ({ data }: { data: TapSensor }): TapSensor => {
                // tapSensorActions.populateTapSensors({ tapSensors: data?.tapSensors })
                toastActions.addToast({ title: "removeAndReplaceTappedKeg" })
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/removeAndReplaceTappedKeg", error)
                toastActions.addToast({ title: "Error: removeAndReplaceTappedKeg" })
            }
        }),
        updateKegOrder: builder.mutation<TapSensor, RearrangeTapSensorAssignedKegs>({
            invalidatesTags: ["TapSensors", "TapSensor"],
            query: (input) => ({
                query: rearrangeTapSensorAssignedKegsMutation,
                args: input
            }),
            transformResponse: ({ data }: { data: TapSensor }): TapSensor => {
                // tapSensorActions.populateTapSensors({ tapSensors: data?.tapSensors })
                toastActions.addToast({ title: "Rearrange assigned kegs" })
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/updateKeg", error)
                toastActions.addToast({ title: "Error: Rearranging  assigned keg" })
            }
        }),
        deleteTapSensor: builder.mutation<void, InputSuperAdminDeleteTapSensor>({
            query: (input) => ({
                query: deleteTapSensorMutation,
                args: input
            })
        })
    })
})

const {
    useAddKegAssignedKegMutation,
    useRemoveAssignedKegMutation,
    useChangeTapOfAssignedKegsMutation,
    useUpdateKegOrderMutation,
    useRemoveAndReplaceTappedKegMutation,
    useDeleteTapSensorMutation
} = tapSensorMutationsApi

export {
    tapSensorMutationsApi,
    useAddKegAssignedKegMutation,
    useDeleteTapSensorMutation,
    useChangeTapOfAssignedKegsMutation,
    useRemoveAndReplaceTappedKegMutation,
    useRemoveAssignedKegMutation,
    useUpdateKegOrderMutation
}
