import { toastActions } from "notifications/src/toasts"
import {
    type CreateKegs,
    type DeleteKeg,
    type InputGetByPpSubId,
    type InputGetKegsByStatus,
    type InputGetPreviouslyReceivedKegs,
    type InputPromoteAssignedKegs,
    type InputSuperAdminPromoteAssignedKegs,
    type Keg,
    type KegConnection,
    KegStatus,
    type RemoveTappedKeg,
    type SetTappedKeg,
    type TapSensor,
    type UpdateKeg
} from "types"
import { promoteAssignedKegsMutation, superAdminPromoteAssignedKegsMutation } from "utilities"
import { createKegsMutation } from "../mutations/private/inventory/createKegsMutation"
import { deleteKegMutation } from "../mutations/private/inventory/deleteKegMutation"
import { removeTappedKegMutation } from "../mutations/private/inventory/removeTappedKegMutation"
import { setTappedKegMutation } from "../mutations/private/inventory/setTappedKegMutation"
import { updateKegMutation } from "../mutations/private/inventory/updateKegMutation"
import { getKegById, getKegsByStatusQuery } from "../queries/private/inventory"
import { getPreviouslyRecievedKegs } from "../queries/private/inventory/getPreviouslyRecievedKegs"
import { baseApi } from "./baseApi"

const inventoryApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus  */
        getInventoryKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["Kegs"],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                let nextToken = undefined
                let results: Keg[] = []
                do {
                    const response = await baseQuery({
                        query: getKegsByStatusQuery,
                        args: {
                            limit: 750,
                            ...arg,
                            nextToken
                        }
                    })
                    results = [...results, ...response.data.items]
                    nextToken = response?.data?.nextToken
                } while (nextToken)
                return { data: results }
            }
        }),
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
        getKegsByStatus: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["Kegs"],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                let nextToken = undefined
                let results: Keg[] = []
                do {
                    const response = await baseQuery({
                        query: getKegsByStatusQuery,
                        args: {
                            ...arg,
                            nextToken
                        }
                    })
                    results = [...results, ...response.data.items]
                    nextToken = response?.data?.nextToken
                } while (nextToken)
                return { data: results }
            }
        }),
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
        getArchivedKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["ArchivedKegs"],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                let nextToken = undefined
                let results: Keg[] = []
                do {
                    const response = await baseQuery({
                        query: getKegsByStatusQuery,
                        args: {
                            ...arg,
                            kegStatus: KegStatus.Depleted,
                            nextToken
                        }
                    })
                    results = [...results, ...response.data.items]
                    nextToken = response?.data?.nextToken
                } while (nextToken)
                return { data: results }
            }
        }),
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
        getAssignedKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["AssignedKegs"],
            query: (input) => ({
                query: getKegsByStatusQuery,
                args: { ...input, kegStatus: KegStatus.Assigned }
            }),
            transformResponse: (data: KegConnection): Keg[] => {
                // TODO: Add notifications
                // inventoryActions.setKegs({ kegs: data.items, kegStatus: KegStatus.Assigned })
                return data.items || []
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getAssignedKegs", error)
            }
        }),
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
        getUnassignedKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["UnassignedKegs"],

            query: (input) => ({
                query: getKegsByStatusQuery,
                args: { ...input, kegStatus: KegStatus.Unassigned }
            }),
            transformResponse: (data: KegConnection): Keg[] => {
                // TODO: Add notifications as needed
                // inventoryActions.setKegs({ kegs: data.items, kegStatus: KegStatus.Unassigned })
                // toastActions.addToast({ title: 'Unassigned queried' })
                return data.items || []
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getUnassignedKegs", error)
            }
        }),
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
        getTappedKegs: builder.query<Keg[], InputGetKegsByStatus>({
            providesTags: ["TappedKegs"],
            query: (input) => ({
                query: getKegsByStatusQuery,
                args: { ...input, kegStatus: KegStatus.Tapped }
            }),
            transformResponse: (data: KegConnection): Keg[] => {
                // TODO: Add notifications as needed
                // inventoryActions.setKegs({ kegs: data.items, kegStatus: KegStatus.Tapped })
                return data.items || []
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getTappedKegs", error)
            }
        }),
        /**
         * https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegByPPSubId
         *
         * https://blx-docs-mono-dev.vercel.app/docs/private/input/inputGetByPPSubId
         */
        getKegById: builder.query<Keg, InputGetByPpSubId>({
            providesTags: ["KegById"],
            query: (input) => ({
                query: getKegById,
                args: { limit: 1, ...input }
            }),
            // transformResponse: (data: Keg): Keg => {
            //     // TODO: Add notifications
            //     inventoryActions.selectKeg(data)
            //     return data
            // },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getKegById", error)
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteKeg
         */
        deleteKeg: builder.mutation<Keg, DeleteKeg>({
            query: (input) => ({
                query: deleteKegMutation,
                args: input
            }),
            invalidatesTags: [
                "Kegs",
                "AssignedKegs",
                "KegById",
                "TappedKegs",
                "OnDeckKegs",
                "UnassignedKegs"
            ],
            transformResponse: (data: Keg): Keg => {
                // TODO: Add notifications as needed
                toastActions.addToast({ title: "Your keg was deleted" })
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/deleteKeg", error)
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createKegs */
        createKegs: builder.mutation<Keg[], CreateKegs>({
            invalidatesTags: ["Kegs", "AssignedKegs", "TappedKegs", "UnassignedKegs"],
            //! NEEDS: TESTING
            query: (input) => ({
                query: createKegsMutation,
                args: input
            }),
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/createKegs", error)
            }
        }),

        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/removeTappedKeg */
        removeTappedKeg: builder.mutation<TapSensor, RemoveTappedKeg>({
            //! NEEDS: TESTING
            query: (input) => ({
                query: removeTappedKegMutation,
                args: input
            }),
            invalidatesTags: [
                "TapSensors",
                "TappedKegs",
                "UnassignedKegs",
                "TappedKegs",
                "AssignedKegs"
            ],
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/removeTappedKeg", error)
            }
        }),

        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/superAdminPromoteAssignedKegs */
        superAdminPromoteAssignedKeg: builder.mutation<
            TapSensor,
            InputSuperAdminPromoteAssignedKegs
        >({
            //! NEEDS: TESTING
            query: (input) => ({
                query: superAdminPromoteAssignedKegsMutation,
                args: input
            }),
            invalidatesTags: ["Kegs", "TapSensors", "UnassignedKegs", "AssignedKegs"],

            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/removeTappedKeg", error)
            }
        }),

        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/promoteAssignedKegs */
        promoteAssignedKeg: builder.mutation<TapSensor, InputPromoteAssignedKegs>({
            query: (input) => ({
                query: promoteAssignedKegsMutation,
                args: input
            }),
            invalidatesTags: [
                "Kegs",
                "KegById",
                "TapSensors",
                "TapSensor",
                "UnassignedKegs",
                "AssignedKegs",
                "TappedKegs"
            ],

            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/promoteAssignedKeg", error)
            }
        }),

        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/setTappedKeg */
        setTappedKeg: builder.mutation<TapSensor, SetTappedKeg>({
            //! NEEDS: TESTING
            query: (input) => ({
                query: setTappedKegMutation,
                args: input
            }),
            invalidatesTags: ["Kegs", "TapSensors", "UnassignedKegs", "AssignedKegs"],

            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/setTappedKeg", error)
            }
        }),

        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateKeg */
        updateKeg: builder.mutation<Keg, UpdateKeg>({
            query: (input) => ({
                query: updateKegMutation,
                args: input
            }),
            invalidatesTags: [
                "TapSensors",
                "TapSensor",
                "Kegs",
                "AssignedKegs",
                "KegById",
                "TappedKegs",
                "OnDeckKegs",
                "UnassignedKegs",
                "ArchivedKegs",
                "PPSub"
            ],
            transformResponse: (data: Keg): Keg => {
                toastActions.addToast({
                    title: "Updated keg",
                    variant: "success",
                    duration: 6000
                })
                return data as Keg
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/updateKeg", error)
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getPreviouslyReceivedKegs */
        getPreviouslyReceivedKegs: builder.query<KegConnection, InputGetPreviouslyReceivedKegs>({
            providesTags: ["Kegs", "AssignedKegs", "UnassignedKegs"],
            query: (input) => ({
                query: getPreviouslyRecievedKegs,
                args: input
            }),
            transformResponse: (data: KegConnection): KegConnection => {
                return data
            },
            transformErrorResponse: (error) => {
                // TODO: Add notifications
                console.error("RTKQ/transformErrorResponse/getKegById", error)
            }
        })
    })
})

const {
    useUpdateKegMutation,
    useGetAssignedKegsQuery,
    useGetInventoryKegsQuery,
    useGetKegByIdQuery,
    useLazyGetKegByIdQuery,
    useGetArchivedKegsQuery,
    useGetTappedKegsQuery,
    useGetUnassignedKegsQuery,
    useDeleteKegMutation,
    useCreateKegsMutation,
    useRemoveTappedKegMutation,
    useSetTappedKegMutation,
    useSuperAdminPromoteAssignedKegMutation,
    useGetPreviouslyReceivedKegsQuery,
    useGetKegsByStatusQuery
} = inventoryApi

export {
    inventoryApi,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createKegs */
    useCreateKegsMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteKeg */
    useDeleteKegMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
    useGetArchivedKegsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
    useGetAssignedKegsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
    useGetInventoryKegsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
    useGetKegByIdQuery,
    /**https://blx-docs-mono-dev.vercel.app/docs/private/query/getPreviouslyReceivedKegs */
    useGetPreviouslyReceivedKegsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
    useGetTappedKegsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegsByStatus */
    useGetUnassignedKegsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getKegById */
    useLazyGetKegByIdQuery,
    useRemoveTappedKegMutation,
    /**
     * @see
     * https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateKeg
     * @see
     * https://blx-docs-mono-dev.vercel.app/docs/private/input/inputUpdateKeg
     */
    useUpdateKegMutation,
    useGetKegsByStatusQuery
}
