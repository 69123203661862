import { toastActions } from "notifications/src/toasts/state/useToasts"
import type {
    Asset,
    InputAssetIds,
    InputCreateAsset,
    InputGetMediaLibrary,
    InputUpdateAsset,
    MediaLibrary
} from "types"
import { createAssetMutation } from "../mutations/private/asset/createAssetMutation"
import { deleteAssetMutation } from "../mutations/private/asset/deleteAssetMutation"
import { updateAssetMutation } from "../mutations/private/asset/updateAssetMutation"
import { getMediaLibraryQuery } from "../queries/private/media/getMediaLibraryQuery"
import { mediaActions } from "../state"
import { baseApi } from "./baseApi"

const mediaApi = baseApi.injectEndpoints({
    // refetchOnMountOrArgChange: true,
    endpoints: (builder) => ({
        /**  https://blx-docs-mono-dev.vercel.app/docs/private/query/getMediaLibrary  */
        getMediaLibrary: builder.query<MediaLibrary, InputGetMediaLibrary>({
            providesTags: ["MediaLibrary", "Brand", "Brands"],
            query: (input) => ({
                query: getMediaLibraryQuery,
                args: input
            }),
            transformResponse: (data: MediaLibrary): MediaLibrary => {
                mediaActions.setMediaLibrary(data)
                return data
            },
            transformErrorResponse: (error) => {
                toastActions.addToast({
                    title: "Error",
                    description: `There was an error getting the media library.`,
                    variant: "error",
                    duration: 6
                })
                console.error("RTKQ/transformErrorResponse/getMediaLibrary", error)
                return error
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createAsset */
        createAsset: builder.mutation<Asset, InputCreateAsset>({
            query: (input) => ({
                query: createAssetMutation,
                args: input
            }),
            invalidatesTags: ["MediaLibrary", "Brand", "Brands"],
            transformResponse: (data: Asset): Asset => {
                toastActions.addToast({
                    title: "Asset Uploaded",
                    variant: "success",
                    duration: 6
                })
                return data
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/createAsset", error)
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteAsset */
        deleteAsset: builder.mutation<Asset, InputAssetIds>({
            query: (input) => ({
                query: deleteAssetMutation,
                args: input
            }),
            invalidatesTags: ["MediaLibrary", "Brand", "Brands"],
            transformResponse: (data: Asset): Asset => {
                toastActions.addToast({
                    title: "Asset Deleted",
                    variant: "success",
                    duration: 6
                })
                return data
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/deleteAsset", error)
            }
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateAsset */
        updateAsset: builder.mutation<Asset, InputUpdateAsset>({
            query: (input) => ({
                query: updateAssetMutation,
                args: input
            }),
            invalidatesTags: ["MediaLibrary"],
            transformResponse: (data: Asset): Asset => {
                toastActions.addToast({
                    title: "Asset Saved",
                    variant: "success",
                    duration: 6
                })
                return data
            },
            transformErrorResponse: (error) => {
                console.error("RTKQ/transformErrorResponse/createAsset", error)
            }
        })
    })
})

const {
    useCreateAssetMutation,
    useGetMediaLibraryQuery,
    useDeleteAssetMutation,
    useUpdateAssetMutation
} = mediaApi

export {
    mediaApi,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/createAsset */
    useCreateAssetMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/deleteAsset */
    useDeleteAssetMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getMediaLibrary */
    useGetMediaLibraryQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateAsset */
    useUpdateAssetMutation
}
