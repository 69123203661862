import { toastActions } from "notifications/src/toasts"
import type {
    InputCreateReport,
    InputGetReports,
    TrackedVarianceReport,
    TrackedVarianceReportConnection
} from "types"
import createVarianceReportMutation from "../mutations/private/varianceReport/createReportMutation"
import getVarianceReportsQuery from "../queries/private/varianceReport/getVarianceReportsQuery"
import { baseApi } from "./baseApi"

const varianceReportsApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        getReports: builder.query<TrackedVarianceReportConnection, InputGetReports>({
            query: (input) => ({
                query: getVarianceReportsQuery,
                args: input
            }),
            transformErrorResponse: () =>
                toastActions.addToast({
                    title: "Error",
                    description: "Failed to fetch reports",
                    variant: "error"
                })
        }),
        createReport: builder.mutation<TrackedVarianceReport, InputCreateReport>({
            invalidatesTags: ["VarianceReports"],
            query: (input) => ({
                query: createVarianceReportMutation,
                args: input
            })
        })
    })
})

const { useCreateReportMutation, useGetReportsQuery } = varianceReportsApi

export { useCreateReportMutation, useGetReportsQuery, varianceReportsApi }
