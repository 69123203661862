export const getProductByIdQuery = /* GraphQL */ `
    query getProductById($input: inputGetById!) {
    getProductById(input: $input) {
            id
            slug
            title
            brand {
                id
                title
                displayTitle
                assets {
                    __typename
                    id
                    alt
                    type
                    caption
                    ... on S3Asset {
                        path
                    }
                    ... on CloudinaryAsset {
                        cloudinaryId
                    }
                }
            }
            assets {
                __typename
                id
                alt
                type
                caption
                ... on S3Asset {
                    path
                }
                ... on CloudinaryAsset {
                    cloudinaryId
                }
            }
            ... on Beer {
                abv
                srm
                style {
                    title
                    id
                }
            }
            ... on Kombucha {
                color                
            }
        }
    }
`
