import { skipToken } from "@reduxjs/toolkit/query"
import { useGetReportsQuery } from "../../api/varianceReportsApi"

const token = (ppSubId: string | undefined): { ppSubId: string } | undefined => {
    if (!ppSubId) return undefined
    if (ppSubId.length === 0) return undefined

    return { ppSubId }
}

export const useGetVarianceReports = (ppSubId: string) => {
    const { data, isLoading } = useGetReportsQuery(token(ppSubId) ?? skipToken)

    return {
        varianceReports: data?.items || [],
        isLoading
    }
}
