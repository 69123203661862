"use client"

import type { InputCreateReport, TrackedVarianceReport } from "types"
import { useCreateReportMutation } from "../../api/varianceReportsApi"

interface IUseCreateVarianceReport {
    endDate: string
    organizationId: string
    ppSubId: string
    startDate: string
    title: string
    uploadedFiles: File[]
}

/**
 * @returns {createTrackedVarianceReport} - Function to create a tracked variance report and upload raw PoS files to the s3 bucket.
 */
export const useCreateTrackedVarianceReport = () => {
    const [createReport] = useCreateReportMutation()

    const createVarianceReport = async ({
        endDate,
        organizationId,
        ppSubId,
        startDate,
        title,
        uploadedFiles
    }: IUseCreateVarianceReport): Promise<TrackedVarianceReport> => {
        const rawFilenames = uploadedFiles.map((file) => file.name)

        if (rawFilenames?.length > 0) {
            const args: InputCreateReport = {
                endDate,
                organizationId,
                ppSubId,
                rawFilenames,
                startDate,
                title
            }

            const { data } = await createReport(args)

            return data
        }
    }
    const uploadFile = async (data: TrackedVarianceReport, formData: FormData) => {
        try {
            const response = await fetch(data.s3RawUploadFields.url, {
                method: "POST",
                body: formData
            })

            if (response.ok) console.log("File uploaded successfully.")
        } catch (error) {
            console.error("File upload failed:", error)
        }
    }
    const createReportUpload = async ({
        endDate,
        organizationId,
        ppSubId,
        startDate,
        title,
        uploadedFiles
    }: IUseCreateVarianceReport) => {
        if (!ppSubId) return

        const data = await createVarianceReport({
            endDate,
            organizationId,
            ppSubId,
            startDate,
            title,
            uploadedFiles
        })

        if (data) {
            const uploadFields = data?.s3RawUploadFields?.fields
            const parsedUploadFields = JSON.parse(uploadFields)
            const formData = new FormData()

            for (const name in parsedUploadFields) {
                formData.append(name, parsedUploadFields[name])
            }

            if (data?.s3RawUploadFields?.url) {
                uploadedFiles.forEach((file) => {
                    formData.set("file", file)
                    uploadFile(data, formData)
                })
            }

            return data
        }
    }
    return {
        createTrackedVarianceReport: createReportUpload
    }
}
