import { createSelector } from "@reduxjs/toolkit"
import type { RootState } from "../store"

const varianceReportState = (state: RootState) => state.activeVarianceReport

const selectActiveVarianceReport = createSelector(varianceReportState, (state) => state)
const selectActiveVarianceReportTitle = createSelector(varianceReportState, (state) => state.title)
const selectActiveVarianceReportMetadata = createSelector(varianceReportState, (state) => state.report.metadata)

export { selectActiveVarianceReport, selectActiveVarianceReportTitle, selectActiveVarianceReportMetadata }
