import { toastActions } from "notifications/src/toasts"
import {
    type Brand,
    type CreateBrand,
    type InputGetById,
    type InputSearchBpl,
    OpenSearchType,
    type UpdateBrand,
    type WildCardTitleSearchConnection
} from "types"
import { datadogLocalErrorLog } from "../datadog/datadogErrorLogger"
import { archiveBrandMutation } from "../mutations/private/brand/archiveBrandMutation"
import { createBrandMutation } from "../mutations/private/brand/createBrandMutation"
import { updateBrandMutation } from "../mutations/private/brand/updateBrandMutation"
import { getBrandByIdBranding } from "../queries/private/brand/getBrandByIdBranding"
import { getBrandByIdPL } from "../queries/private/brand/getBrandByIdPL"
import { brandByIdQueryDashboard } from "../queries/private/brand/getBrandByIdQuery-DashBoard"
import { getBrandByIdQuery } from "../queries/private/brand/getBrandByIdQuery-General"
import { getLocations } from "../queries/private/brand/getBrandByIdQuery-Locations"
import { getBrandById } from "../queries/private/brand/getBrandByIdQuery-MMB-Dashboard"
import { allProductsQuery } from "../queries/private/brand/getBrandByIdQuery-allProducts"
import { getLocationsQuery } from "../queries/private/brand/getLocationsQuery"
import { searchBPBrandsQuery } from "../queries/private/brand/searchBPBrandsQuery"
import { searchBPLQuery } from "../queries/private/brand/searchBPLQuery"
import { searchBrandsQuery } from "../queries/private/brand/searchBrandsQuery"
import { brandActions, userActions } from "../state"
import { baseApi } from "./baseApi"

const brandApiLog = datadogLocalErrorLog("brandApi")

const brandApi = baseApi.injectEndpoints({
    endpoints: (builder) => ({
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/searchBPL */
        searchBrands: builder.query<Brand[], string>({
            //! NEEDS: TESTING
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: searchBrandsQuery,
                args: {
                    limit: 750,
                    typeFilters: [OpenSearchType.Brand],
                    search: {
                        wildcardText: "",
                        searchFieldsBrands: {
                            title: input
                        }
                    }
                }
            }),
            transformResponse: (data: WildCardTitleSearchConnection) => {
                // TODO: Add notifications
                //! NEEDS: brandActions
                return data.items as Brand[]
            },
            transformErrorResponse: brandApiLog("searchBrands")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/searchBPL */
        searchBPL: builder.query<WildCardTitleSearchConnection, InputSearchBpl>({
            //! NEEDS: TESTING
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: searchBPLQuery,
                args: input
            }),
            transformResponse: (
                data: WildCardTitleSearchConnection
            ): WildCardTitleSearchConnection => {
                // TODO: Add notifications
                //! NEEDS: brandActions
                return data as WildCardTitleSearchConnection
            },
            transformErrorResponse: brandApiLog("searchBPL")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/searchBPL */
        searchBPBrands: builder.query<WildCardTitleSearchConnection, InputSearchBpl>({
            //! NEEDS: TESTING
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: searchBPBrandsQuery,
                args: input
            }),
            transformResponse: (
                data: WildCardTitleSearchConnection
            ): WildCardTitleSearchConnection => {
                // TODO: Add notifications
                //! NEEDS: brandActions
                return data
            },
            transformErrorResponse: brandApiLog("searchBPBrands")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
        getAssociatedBrand: builder.query<Brand, InputGetById>({
            /*  https://blx-docs-mono-dev.vercel.app/docs/private/query/getPPSubById **/
            providesTags: ["AssociatedBrands"],
            query: (input) => ({
                query: getBrandById,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                userActions.setUserBrandAssociations(data)
                // useToasts.getState().addToast({ title: 'Populated Associated Brand' })
                return data
            },
            transformErrorResponse: brandApiLog("getAssociatedBrand")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
        getBrandById: builder.query<Brand, InputGetById>({
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: brandByIdQueryDashboard,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                brandActions.populateBrandState({ brand: data })
                // toastActions.addToast({ title: 'Brand Loaded' })
                return data
            },
            transformErrorResponse: brandApiLog("getBrandById")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
        getBrandByIdAllProducts: builder.query<Brand, InputGetById>({
            //! NEEDS: TESTING
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: allProductsQuery,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                // TODO: Add notifications
                //! NEEDS: brandActions
                return data
            },
            transformErrorResponse: brandApiLog("getBrandByIdAllProducts")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
        getBrandByIdMMBDashboard: builder.query<Brand, InputGetById>({
            //! NEEDS: TESTING
            providesTags: ["Brand", "Locations", "Products"],
            query: (input) => ({
                query: brandByIdQueryDashboard,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                // TODO: Add notifications
                //! NEEDS: brandActions
                return data
            },
            transformErrorResponse: brandApiLog("getBrandByIdMMBDashboard")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
        getBrandByIdPP: builder.query<Brand, InputGetById>({
            //! NEEDS: TESTING
            providesTags: ["Brand", "Locations", "Products"],
            query: (input) => ({
                query: getBrandByIdQuery,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                // TODO: Add notifications
                //! NEEDS: brandActions
                return data
            },
            transformErrorResponse: brandApiLog("getBrandByIdPP")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
        getBrandByIdLocations: builder.query<Brand, InputGetById>({
            //! NEEDS: TESTING
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: getLocations,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                // TODO: Add notifications
                //! NEEDS: brandActions
                return data
            },
            transformErrorResponse: brandApiLog("getBrandByIdLocations")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
        getLocationsBrandById: builder.query<Brand, InputGetById>({
            //! NEEDS: TESTING
            providesTags: ["Brands", "Locations", "Products"],
            query: (input) => ({
                query: getLocationsQuery,
                args: { ...input }
            }),
            transformResponse: (data: Brand): Brand => {
                // TODO: Add notifications
                //! NEEDS: brandActions
                return data
            },
            transformErrorResponse: brandApiLog("getLocationsBrandById")
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
        getBrandByIdBranding: builder.query<Brand, InputGetById>({
            providesTags: ["Brand"],
            query: (input) => ({
                query: getBrandByIdBranding,
                args: input
            }),
            transformResponse: (data: Brand): Brand => {
                // toastActions.addToast({ title: 'Brand Loaded' })
                // brandActions.setBrandFormData({ brandFormData: data })

                return data
            },
            transformErrorResponse: (error, meta) => {
                // TODO: Add notifications
                toastActions.addToast({ title: "Failed to Load Brand" })
                brandApiLog("searchBrands")(error, meta)
            }
        }),
        getBrandByIdPL: builder.query<Brand, InputGetById>({
            providesTags: ["Brand"],
            query: (input) => ({
                query: getBrandByIdPL,
                args: input
            })
        }),
        /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrand */
        updateBrand: builder.mutation<Brand, UpdateBrand>({
            query: (input) => ({
                query: updateBrandMutation,
                args: input
            }),
            transformResponse: (brandData: Brand): Brand => {
                toastActions.addToast({
                    title: "Brand updated",
                    description: "Brand updated",
                    variant: "success",
                    duration: 3000
                })
                brandActions.populateBrandState({
                    brand: {
                        ...brandActions.getBrand,
                        ...brandData
                    }
                })

                return brandData
            },
            invalidatesTags: ["Brand", "Products"],
            transformErrorResponse: brandApiLog("updateBrand")
        }),
        PLUpdateBrand: builder.mutation<Brand, UpdateBrand>({
            invalidatesTags: ["Brand", "Products"],
            query: (input) => ({
                query: updateBrandMutation,
                args: input
            })
        }),
        createBrand: builder.mutation<Brand, CreateBrand>({
            invalidatesTags: ["Brand", "Products"],
            queryFn: async (arg, _api, _extraOptions, baseQuery) => {
                const socialArray = Object.entries(arg?.socialMedia)
                    .map(([key, value]) => {
                        if (value)
                            return {
                                platform: key,
                                handle: value as unknown as string
                            }
                    })
                    .filter((social) => social !== undefined)

                arg.socialMedia = socialArray

                const { data } = await baseQuery({
                    query: createBrandMutation,
                    args: arg
                })

                return { data: data as Brand }
            }
        }),
        archiveBrand: builder.mutation<{ id: string }, string>({
            invalidatesTags: ["Brand", "Brands", "Locations", "Products"],
            query: (brandId) => ({
                query: archiveBrandMutation,
                args: {
                    brandId
                }
            })
        })
    })
})

const {
    useGetBrandByIdAllProductsQuery,
    useGetBrandByIdLocationsQuery,
    useGetBrandByIdMMBDashboardQuery,
    useGetBrandByIdPPQuery,
    useGetBrandByIdQuery,
    useGetBrandByIdBrandingQuery,
    useGetLocationsBrandByIdQuery,
    useLazyGetBrandByIdAllProductsQuery,
    useLazyGetBrandByIdLocationsQuery,
    useLazyGetBrandByIdMMBDashboardQuery,
    useLazyGetBrandByIdPPQuery,
    useLazyGetBrandByIdQuery,
    useLazyGetBrandByIdBrandingQuery,
    useLazyGetLocationsBrandByIdQuery,
    useLazySearchBPBrandsQuery,
    useLazySearchBPLQuery,
    useLazySearchBrandsQuery,
    useSearchBPBrandsQuery,
    useSearchBPLQuery,
    useSearchBrandsQuery,
    useGetBrandByIdPLQuery,
    useUpdateBrandMutation,
    usePLUpdateBrandMutation,
    useCreateBrandMutation,
    useArchiveBrandMutation
} = brandApi

export {
    brandApi,
    useCreateBrandMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useGetBrandByIdAllProductsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useGetBrandByIdBrandingQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useGetBrandByIdLocationsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useGetBrandByIdMMBDashboardQuery,
    useGetBrandByIdPLQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useGetBrandByIdPPQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useGetBrandByIdQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useGetLocationsBrandByIdQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useLazyGetBrandByIdAllProductsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useLazyGetBrandByIdBrandingQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useLazyGetBrandByIdLocationsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useLazyGetBrandByIdMMBDashboardQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useLazyGetBrandByIdPPQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useLazyGetBrandByIdQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/getBrandById */
    useLazyGetLocationsBrandByIdQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/searchBPL */
    useLazySearchBPBrandsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/searchBPL */
    useLazySearchBPLQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/searchBPL */
    useLazySearchBrandsQuery,
    usePLUpdateBrandMutation,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/searchBPL */
    useSearchBPBrandsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/searchBPL */
    useSearchBPLQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/query/searchBPL */
    useSearchBrandsQuery,
    /** https://blx-docs-mono-dev.vercel.app/docs/private/mutation/updateBrand */
    useUpdateBrandMutation,
    useArchiveBrandMutation
}
